<template>
  <div>
    <!-- <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-radio>
        </div>
      </template>
    </c-search-box> -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="mstTable"
          title="유해인자 대분류"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          rowKey="code"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="LBLADD" icon="add" @btnClicked="addrowType" v-if="editable" />
            <c-btn label="LBLSAVE" icon="save" @btnClicked="saveType" v-if="editable" />
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag != 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                @click.stop="() => { childEditable = true, rowClick(props.row) }">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="grid"
          title="유해인자 중분류"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :isExcelDown="false"
          :data="grid.data"
          rowKey="code"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addrowIns" v-if="editable && childEditable" />
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveIns" v-if="editable && childEditable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'harmful_type_master',
  data() {
    return {
      mstGrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '대분류',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            style: 'width:70px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '중분류',
            style: 'width:70px',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '중분류',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            style: 'width:70px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      popupData: {
        first: '',
        type : null,
        plantCd: '',
      },
      childEditable: false
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getCodeMst();
    },
    getCodeMst() {
      this.grid.data = [];
      this.$http.url = this.$format(selectConfig.sys.code.mst.list.url, 'HAZARD_CLASS_FST_CD');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    rowClick(row) {
      this.$http.url = this.$format(selectConfig.sys.code.mst.list.attr.url, 'HAZARD_CLASS_SEC_CD', row.code);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.popupData.first = row.code;
      },);
    },
    addrowIns() {
      let len = this.grid.data.length + 1;
      this.grid.data.push({
        codeGrpCd: 'HAZARD_CLASS_SEC_CD',  // 코드그룹
        code: uid(),  // 코드
        codeName: '',  // 코드명
        useFlag: 'Y',  // 사용여부
        sortOrder: len,  //
        attrVal1: this.popupData.first,
        editFlag: 'C',
      });
    },
    saveIns() {
      let checkItem = ['codeName', 'useFlag']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [중분류, 사용여부]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = transactionConfig.sai.hazard.master.insert.url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.rowClick({ code: this.popupData.first});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    // removeRowIns() {
    //   let selectData = this.$refs['grid'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: 'LBLCONFIRM',
    //       message: 'MSGREMOVE', // 삭제하시겠습니까?,
    //       type: 'warning', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = transactionConfig.sys.code.mst.delete.url;
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(selectData)
    //         };
    //         this.$http.request(() => {
    //           // this.rowClick(this.selectedRows);
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
              
    //           this.$_.forEach(selectData, item => {
    //             this.grid.data = this.$_.reject(this.grid.data, item);
    //           })
    //           this.$refs['grid'].$refs['compo-table'].clearSelection();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    addrowType() {
      let len = this.mstGrid.data.length + 1;
      this.mstGrid.data.push({
        codeGrpCd: 'HAZARD_CLASS_FST_CD',  // 코드그룹
        code: uid(),  // 코드
        codeName: '',  // 코드명
        useFlag: 'Y',  // 사용여부
        sortOrder: len,  //
        editFlag: 'C',
      });
    },
    saveType() {
      let checkItem = ['codeName', 'sortOrder']
      let isConti = true;
      this.$_.forEach(this.mstGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [대분류, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = transactionConfig.sai.hazard.master.insert.url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getCodeMst();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    // removeType() {
    //   let selectData = this.$refs['mstTable'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: 'LBLCONFIRM',
    //       message: 'MSGREMOVE', // 삭제하시겠습니까?,
    //       type: 'warning', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = transactionConfig.sys.code.mst.delete.url;
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(selectData)
    //         };
    //         this.$http.request(() => {
    //           // this.getCodeMst();
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
              
    //           this.$_.forEach(selectData, item => {
    //             this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
    //           })
    //           this.$refs['mstTable'].$refs['compo-table'].clearSelection();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
  }
};
</script>
