var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c(
            "c-table",
            {
              ref: "mstTable",
              attrs: {
                title: "유해인자 대분류",
                tableId: "mstTable",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                columns: _vm.mstGrid.columns,
                isExcelDown: false,
                data: _vm.mstGrid.data,
                rowKey: "code",
              },
              on: { rowClick: _vm.rowClick },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props }) {
                    return [
                      [
                        props.row.editFlag != "C"
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  outline: "",
                                  square: "",
                                  color: "blue",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() => {
                                      ;(_vm.childEditable = true),
                                        _vm.rowClick(props.row)
                                    }).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" ▶ ")]
                            )
                          : _vm._e(),
                      ],
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addrowType },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLSAVE", icon: "save" },
                        on: { btnClicked: _vm.saveType },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c(
            "c-table",
            {
              ref: "grid",
              attrs: {
                title: "유해인자 중분류",
                tableId: "table",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                isExcelDown: false,
                data: _vm.grid.data,
                rowKey: "code",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.childEditable
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addrowIns },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.childEditable
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveIns },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }